import React, { Suspense, lazy, useEffect, useState } from "react";
import { useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
// Sanity
import { getClient } from "../client";
import { groq } from "next-sanity";
import {
  propsSanity,
  groqSanityPageQuery,
  sanitySrcUrl,
} from "../lib/libFunctions";

// Styles
import { base } from "../styles/base/_allBase.styles";
const {
  GridItem_HeaderSection,
  GridContainer_Page_Layout,
  Page_Main_Grid_Container,
} = base;
// Components
import SectionVideo from "../components/section-one-video/section-one-video.jsx";
import MarketingPopUp from "../components/pop-up/pop-up-component.jsx";
import HorizontalScroll from "../components/horizontal-scroll/horizontal-scroll.component";
import CarouselBoniDay from "../components/carousel-boni-day/carousel-boni-day";
import DragSliderSection from "../components/drag-slider-section/drag-slider-section";
import ReviewsSection from "../components/google-reviews/reviews-section.jsx";
import { HeaderTwoColumGrid } from "../components/header-w-two-column-grid/header-w-two-column-grid";
import { SectionCarousel } from "../components/carousel-section/carousel-section.component";
import { Header } from "../components/header/header";
import { Footer } from "../components/footer/footer";
import { CookieConsentComp } from "../components/cookie-concent/cookie-concent.component.jsx";

function Home(props) {
  // const [rating, setRating] = useState([]);
  const sectionOneRef = useRef();
  const router = useRouter();
  const canonicalUrl = `https://www.bonibeach.com${
    router.asPath.split("?")[0]
  }`;

  const {
    header,
    boniDay,
    roomsCarousel,
    headerTwoColumnText,
    dragSlider,
    BoniBeachRestaurant,
    BoniRooftop,
    PageContactSection,
    videoHeader,
  } = props.data.data;
  const { seo } = props.data;
  const { ratingValue, ratingCount, reviews } = props.reviews;

  const aggregateRating = {
    "@context": "http://schema.org",
    "@type": "Hotel",
    name: "Boni Beach Lombok | Hotel & Restaurant",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: ratingValue,
      reviewCount: ratingCount,
    },
  };

  function filterReviews(reviews) {
    return reviews.filter((review) => review.rating === 5);
  }

  return (
    <GridContainer_Page_Layout>
      <Head>
        <title>{`Boni Beach Lombok | ${seo?.metaTitle}`}</title>
        <meta name="description" content={seo.metaDesc} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={canonicalUrl} />
        {/* Conditionally noindex pages with query parameters */}
        {router.asPath.includes("?") && (
          <meta name="robots" content="noindex, follow" />
        )}
        {/* Open Graph tags */}
        <meta
          property="og:title"
          content={`Boni Beach Lombok | ${seo?.metaTitle}`}
        />
        <meta property="og:description" content={seo.metaDesc} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={sanitySrcUrl(header?.photo)} />
        <script type="application/ld+json">
          {JSON.stringify(aggregateRating)}
        </script>
      </Head>

      <GridItem_HeaderSection>
        <header ref={sectionOneRef}>
          <Header />
        </header>
      </GridItem_HeaderSection>
      <main>
        <Page_Main_Grid_Container>
          <Suspense fallback={<div>Loading...</div>}>
            {/* <SectionOne header={header} /> */}
            <SectionVideo video={videoHeader} />
            <MarketingPopUp />
            <ReviewsSection reviews={filterReviews(reviews)} />
            <DragSliderSection data={dragSlider} />
            <CarouselBoniDay imageArray={boniDay.items} />
          </Suspense>
          <SectionCarousel array={roomsCarousel.array} />
          <HeaderTwoColumGrid data={headerTwoColumnText} />
          <Suspense fallback={<div>Loading...</div>}>
            <HorizontalScroll data={BoniBeachRestaurant} direction="left" />
            <HorizontalScroll data={BoniRooftop} direction="right" />
          </Suspense>
          <CookieConsentComp />
          <Footer
            toTopRef={sectionOneRef}
            data={PageContactSection.content[0]}
            data2={PageContactSection}
          />
        </Page_Main_Grid_Container>
      </main>
    </GridContainer_Page_Layout>
  );
}

export async function getServerSideProps() {
  const query = groqSanityPageQuery("home-page");
  const data = await getClient().fetch(query, {});

  let reviewsData = {};
  try {
    // Replace with absolute URL if necessary
    const res = await fetch("https://www.bonibeach.com/api/reviews");
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    reviewsData = await res.json();
  } catch (error) {
    return {
      props: { data: propsSanity(data), reviewsError: true },
    };
  }

  return {
    props: {
      data: propsSanity(data),
      reviews: {
        reviews: reviewsData.reviews,
        ratingValue: reviewsData.rating,
        reviewCount: reviewsData.user_ratings_total,
      },
    },
  };
}

export default Home;
