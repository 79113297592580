import { styled, motion, Img, colors, breakpoints, typo } from '../../styles/base/_allBase.styles';

// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const VideoContainer = styled(motion.div)`
	width: 100%; /* Make video container full width */
	${'' /* height: 100vh; */}
	/* Set initial height to zero */
	padding-top: 56.25%; /* Maintain aspect ratio for 16:9 videos */
	position: relative;
	@media ${xxSmallDevices} {
		height: 45vh;
	}
	@media ${xxSmallDevicesLandscape} {
		height: 100vh;
	}
	@media ${extraSmallDevices} {
		height: 45vh;
	}
	@media ${extraSmallDevicesLandscape} {
		height: 100vh;
	}
	@media ${mediumDevices} {
		height: 55vh;
	}
	@media ${mediumDevicesLandscape} {
		height: 55vh;
	}
	@media ${largeDevices} {
		height: 65vh;
	}
	@media ${extraLargeDevices} {
		height: 100vh;
	}
	@media ${xxLargeDevices} {
		height: 100vh;
	}
`;

export const Video = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;
