import React, { useRef, useEffect, useState } from 'react';
import { motion, useMotionValue, useScroll, useSpring, useDragControls, useTransform } from 'framer-motion';
import { Box, Slider, ControlsDiv, Control } from './drag-slider.styles';

const DragSlider = ({ children }) => {
	const [sliderWidth, setSliderWidth] = useState(0);
	const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
	const [sliderConstraints, setSliderConstraints] = useState(0);
	const [currentPercent, setCurrentPercent] = useState(0);
	const ref = useRef();
	const { scrollXProgress, scrollX } = useScroll(ref);
	scrollXProgress.onChange(setCurrentPercent);

	useEffect(() => {
		if (!ref && !ref.current) return;

		const calcSliderChildrenWidth = () => {
			setSliderChildrenWidth(ref?.current?.scrollWidth);
		};

		calcSliderChildrenWidth();

		const calcSliderWidth = () => {
			setSliderWidth(ref?.current?.clientWidth);
		};

		calcSliderWidth();
		window.addEventListener('resize', calcSliderWidth);

		const calcSliderConstraints = () => {
			setSliderConstraints(sliderChildrenWidth - sliderWidth);
		};

		calcSliderConstraints();
		window.addEventListener('resize', calcSliderConstraints);
	}, [ref, sliderChildrenWidth, sliderWidth]);

	const x = useSpring(0, { stiffness: 300, damping: 50 });

	useEffect(() => {
		function updateOpacity() {
			const a = parseFloat(-x.get().toFixed(2));
			const b = parseFloat(sliderChildrenWidth.toFixed(2));
			const percent = Number(a / b).toFixed(2);
			// console.log(a, b, percent);
			setCurrentPercent(percent);
		}
		const unsubscribeX = x.onChange(updateOpacity);

		return () => {
			unsubscribeX();
		};
	}, [sliderChildrenWidth]);

	const elementWidth = sliderChildrenWidth / children.length;

	function setPosition(i) {
		if (elementWidth * i <= sliderConstraints) {
			x.set(-elementWidth * i);
		} else {
			x.set(-sliderConstraints);
		}
	}

	function isInView(i) {
		const elementWidth = sliderChildrenWidth / children.length;
		const currentInView = { min: Math.floor(sliderChildrenWidth * currentPercent) - 10, max: Math.ceil(sliderChildrenWidth * currentPercent + sliderWidth) + 10 };
		const elementPosition = { min: (elementWidth * Number(i)).toFixed(0), max: Number(elementWidth * Number(i) + elementWidth).toFixed(0) };
		const testLowRange = elementPosition.min >= currentInView.min;
		const testHighRange = elementPosition.max <= currentInView.max;

		if (testLowRange && testHighRange) {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	}
	const dragControls = useDragControls();

	return (
		<div>
			<Box>
				<Slider
					ref={ref}
					style={{ x }}
					drag='x'
					dragControls={dragControls}
					dragListener={false}
					initial={{ x: 0 }}
					dragConstraints={{
						right: 0,
						left: -sliderConstraints,
					}}
					dragTransition={{ bounceStiffness: 50, bounceDamping: 10 }}
				>
					{React.Children.map(children, (child, i) => {
						return React.cloneElement(child, {
							onPointerDown: (e) => {
								dragControls.start(e);
							},
							onTouchStart: (e) => {
								dragControls.start(e);
							},
							onTouchMove: (e) => {
								dragControls.start(e);
							},
						});
					})}
					{/* {children} */}
				</Slider>
			</Box>
			<ControlsDiv>
				{children.map((child, i) => {
					const highlight = isInView(i);
					return <Control key={child.key} highlight={highlight.toString()} onClick={() => setPosition(i)} />;
				})}
			</ControlsDiv>
		</div>
	);
};

export default DragSlider;
