import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import * as colors from '../../styles/abstracts/_variables.styles';
import * as breakpoints from '../../styles/abstracts/_breakpoints';
// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const Group = styled(motion.div)`
	position: relative;
	margin: 1px 0;
`;

export const Label = styled(motion.label)`
	font-family: 'NimbusSans', sans-serif;
	color: black;
	font-size: ${(props) => (props.label == '' ? '1rem' : '1rem')};
	font-weight: 200;
	position: absolute;
	pointer-events: none;
	left: 5px;
	top: 5px;
	transition: 300ms ease all;
	top: ${(props) => (props.label == '' ? '0px' : '-1.3rem')};
	input[type='date']::-webkit-inner-spin-button,
	input[type='date']::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}
	@media ${extraSmallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		font-size: ${(props) => (props.label == '' ? '1.25rem' : '1rem')};
	}
	@media ${xxLargeDevices} {
		font-size: ${(props) => (props.label == '' ? '1.5rem' : '1rem')};
	}
`;

export const InputText = styled(motion.input)`
	font-family: 'NimbusSans', sans-serif;
	background: none;
	background-color: inherit;
	color: black;
	font-size: 1.5rem;
	padding: 1rem 1rem;
	display: block;
	width: 100%;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid black;
	margin: 1.5rem 0;
	&:focus ~ ${Label} {
		font-size: 1rem;
		top: -1.3rem;
	}
	@media ${extraSmallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		font-size: 1.25rem;
	}
	@media ${xxLargeDevices} {
		font-size: 1.5rem;
	}
`;

export const TextArea = styled(motion.textarea)`
	font-family: 'NimbusSans', sans-serif;
	background: none;
	background-color: inherit;
	color: black;
	font-size: 18px;
	padding: 2rem 2rem 2rem 2rem;
	display: block;
	height: 8rem;
	width: 100%;
	border: none;
	border-radius: 0;
	border: 1px solid black;
	margin: 2rem 0;
	&:focus ~ ${Label} {
		font-size: 1rem;
		top: -1.3rem;
	}
	@media ${extraSmallDevices} {
	}
	@media ${mediumDevices} {
	}
	@media ${largeDevices} {
		background: none;
		color: black;
		font-size: 18px;
		padding: 10px 10px 10px 5px;
		display: block;
		height: 8rem;
		width: 100%;
		border: none;
		border-radius: 0;
		border: 1px solid black;
		margin: 25px 0;
		&:focus ~ ${Label} {
			font-size: 1rem;
			top: -2rem;
		}
	}

	@media ${extraLargeDevices} {
	}
`;

export const CheckBoxInput = styled(motion.input)`
	display: inline-block;
	width: 16px;
	height: 16px;
	background: ${(props) => (props.checked ? 'green' : 'black')};
	border-radius: 3px;
	transition: all 150ms;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

const ToggleInput = styled.input`
	height: 0;
	width: 0;
	opacity: 0;
	z-index: -1;
`;

export const ToggleSize = styled(motion.div)`
	position: relative;
	display: inline-block;
	font-size: ${(props) => {
		if (props.size === 'xs') return '4px';
		if (props.size === 'sm') return '8px';
		if (props.size === 'lg') return '12px';
		return '10px';
	}};
	width: 6em;
	height: 3.4em;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

	${ToggleInput} {
		opacity: 0;
		width: 0;
		height: 0;
	}
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		font-size: ${(props) => {
			if (props.size === 'xs') return '6px';
			if (props.size === 'sm') return '8px';
			if (props.size === 'lg') return '12px';
			return '10px';
		}};
	}
	@media ${xxLargeDevices} {
	}
`;

const Input = styled.input`
	${
		'' /* height: 0;
	width: 0;
	opacity: 0;
	z-index: -1; */
	}
`;

const LabelSlider = styled.label`
	${
		'' /* position: relative;
	display: inline-block;
	font-size: 1rem;
	width: 6em;
	height: 3.4em;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	${Input} {
		opacity: 0;
		width: 0;
		height: 0;
	} */
	}
`;

const Slider = styled.span`
	${
		'' /* position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 3.4em;

	&::before {
		position: absolute;
		content: '';
		height: 2.6em;
		width: 2.6em;
		left: 0.4em;
		bottom: 0.4em;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	${Input}:checked + & {
		background-color: #ffbbff;
	}

	${Input}:checked + &::before {
		-webkit-transform: translateX(2.6em);
		-ms-transform: translateX(2.6em);
		transform: translateX(2.6em);
	}

	${Input}:focus + & {
		box-shadow: 0 0 0.1em #2196f3;
	}

	${Input}:disabled + & {
		pointer-events: none;
		background: #e6e6e6;
	} */
	}
`;
