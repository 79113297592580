import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from "next/legacy/image";

import * as colors from '../../styles/abstracts/_variables.styles';
import * as breakpoints from '../../styles/abstracts/_breakpoints';
import * as typo from '../../styles/abstracts/_typography.styles';

// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const GridCardSection = styled(motion.div)`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	padding: 1rem;
	gap: 1rem;
	width: 86vw;
	height: auto;
	min-height: 100%;
	margin: 4rem auto 0.5rem auto;
	scrollbar-width: none; // for Firefox
	&::-webkit-scrollbar {
		display: none; // for Chrome, Safari, and Opera
	}
`;

export const ReviewCard = styled(motion.div)`
	flex-shrink: 0;
	justify-content: center;
	min-height: 100%;
	height: auto;
	width: 100%;
	flex: 0 0 auto;
	width: 85%; // Adjust card width as necessary
	background: ${colors.warm_palatte_tertiary};
	${'' /* border-radius: 8px; */}
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 1rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	position: relative;

	@media ${xxSmallDevices} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
		width: 44%;
	}

	@media ${mediumDevices} {
		width: 47%;
	}
	@media ${mediumDevicesLandscape} {
		width: 48%;
	}
	@media ${largeDevices} {
		width: 48%;
	}
	@media ${extraLargeDevices} {
		width: 31.5%;
	}
	@media ${xxLargeDevices} {
		width: 31.5%;
	}
`;

export const ReviewHeader = styled.div`
	display: flex;
	align-items: center;
	margin: 0rem;
`;

export const ReviewerName = styled.h3`
	font-weight: bold;
`;

export const ReviewContent = styled.div`
	flex-grow: 1;
`;

export const ReviewText = styled.p`
	color: #333;
	margin-bottom: 1rem;
`;

export const StarRating = styled.div`
	display: flex;
	align-items: center;
	${'' /* margin-left: auto; // Push to the right of the container */}
	color: #ffa500; // Orange color for stars
	padding: 0.5rem;
	margin: 1rem 0rem;
`;

export const ReadMoreButton = styled.button`
	color: #007bff;
	background: transparent;
	border: none;
	padding: 0rem;
	padding-top: 1rem;
	cursor: pointer;
	margin: 1rem 0rem;
`;

export const ProfileImage = styled.img`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 1rem;
`;

export const ReviewerInfo = styled.div`
	display: flex;
	align-items: center;
`;

export const ReviewDate = styled.p`
	font-size: 0.8rem; // Small text for the date
	color: #757575; // Grey color for the date, adjust as needed
`;
