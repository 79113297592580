import { styled, motion, colors, breakpoints, typo } from '../../styles/base/_allBase.styles';

const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const PopUpContainer = styled(motion.div)`
	height: 45vh;
    width: 60vw;
	background-color: ${colors.warm_palatte_neutral_light};
    position: fixed;
    top: 27%;
    left: 20%;
    padding: 0rem 10rem;
    opacity: 85%;
	@media ${xxSmallDevices} {
	height: 40vh;
    width: 90vw;
    top: 10%;
    left: 5%;
    padding: 0rem 2rem;
    }
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	height: 40vh;
    width: 90vw;
    top: 10%;
    left: 5%;
    padding: 0rem 2rem;
    }
	@media ${extraSmallDevicesLandscape} {
	
    }
	@media ${mediumDevices} {
    height: 30vh;
    width: 90vw;
    top: 10%;
    left: 5%;
    padding: 0rem 2rem;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;

export const CloseDiv = styled(motion.div)`
	position absolute;
    right: 2rem;
    top: 2rem;
    width: 1.8rem;
    cursor: pointer;
	@media ${xxSmallDevices} {
	
    
    }
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
	}
	@media ${xxLargeDevices} {
	}
`;