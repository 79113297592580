import { styled, motion, Img, colors, breakpoints, typo } from '../../styles/base/_allBase.styles';
import leftArrow from '../../assets/svg/left-arrow.svg';

export const Container = styled(motion.div)`
	position: relative;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.contrast};
	cursor: none;
	opacity: 1;
`;

export const HalfScreenButton = styled.div`
	width: 100%;
	height: 100%;
	border: 1rem solid green;
	opacity: 0.5;
`;

export const Cursor = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 5rem;
	height: 5rem;
	border-radius: 5rem;
	background: url(${leftArrow});
	background-color: ${(props) => props.theme.colors.secondary};
	border: 1rem solid ${(props) => props.theme.colors.secondary};
	mix-blend-mode: difference;
	z-index: 999;
	pointer-events: none;
`;

export const ButtonDiv = styled(motion.div)`
	height: 100%;
	width: 50%;
	left: ${(props) => (props.right ? '50%' : '0%')};
	position: absolute;
	z-index: 1;
	opacity: 1;
	object-fit: cover;
`;

export const HeaderContainer = styled(motion.div)`
	position: relative;
	top: 12rem;
	left: 6rem;
	display: flex;
	z-index: 2;
	height: 12rem;
	gap: 2rem;
	width: 70%;
	align-items: center;
`;

export const StackedContainer = styled(motion.div)`
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	-webkit-transition: opacity 1s ease-in-out;
	-moz-transition: opacity 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;
`;
