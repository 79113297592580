import React from 'react';
import { sanitySrcUrl } from '../../lib/libFunctions';
// Styles
import { base, imageStyles, animations, AnimatePresence } from '../../styles/base/_allBase.styles';
const { H3,H4, H5, CenterDiv, StyleP,SmallP,  } = base;
const { ImgCover, SmallImage } = imageStyles;
import { PopUpContainer, CloseDiv } from './pop-up-styles';
import { createEventOptions } from '../../lib/libFunctions';

// Components
import { CustomButton } from '../button/button.component';

export default function MarketingPopUp({ header }) {
    const [isVisible, setIsVisible] = React.useState(true);

    React.useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const windowHeight = window.innerHeight;
          const pageHeight = document.documentElement.scrollHeight;
    
          if (scrollPosition > pageHeight * 0.02) {
            setIsVisible(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    

    const handleClose = () => {
        setIsVisible(false); // Hide popup on click
    };

    function handleSendEventClick(eventName) {
		// const eventName = 'whatsapp_click';
		const eventOptions = createEventOptions('marketing');

		gtag('event', eventOptions[eventName].event, {
			event_category: eventOptions[eventName].category,
			event_label: eventOptions[eventName].label,
			page_path: eventOptions[eventName].page,
		});

		gtag('event', 'conversion', {
			send_to: 'AW-11381423161/iri1COixhvIYELn4irMq',
		});
	}

	return (
        <AnimatePresence initial={true} exitBeforeEnter>
        {isVisible && (
            <PopUpContainer {...animations.fadeInOutAnimation}>
                <CloseDiv {...animations.motionHoverLarge} onClick={handleClose}>
                    <StyleP center>X</StyleP>
                </CloseDiv>
                <CenterDiv>
                    <H3 center>November Special</H3>
                    <StyleP center>First 20 Customers to use promo code:</StyleP>
                    <br />
                    <H5 center>NOVEMBER24</H5>
                    <br />
                    <StyleP center>Stay 2 nights and get third night free</StyleP>
                    <StyleP center>plus 50% off all spa treatments</StyleP>
                    <br />
                    <SmallP center>
                        Stay period is 1st to 30th of November and discount is not valid outside this period. 
                    </SmallP>
                    <br />
                    <CustomButton href="https://secure.guestpro.net/bonibeach" onClick={() => handleSendEventClick('marketing_booking_click')}>Book Now</CustomButton>
                </CenterDiv>
            </PopUpContainer>
        )}
        </AnimatePresence>
    
	);
}

// export { SectionOne };
