import React, { useState, useEffect, useRef, Children } from 'react';
import { useCursorPosition, useCursorChange, useWrap, useCarousel, useParallaxInView } from '../../lib/hooks';
import { sanitySrcUrl } from '../../lib/libFunctions';
// Styles
import { base, styled, AnimatePresence, imageStyles, motion, Img, colors, breakpoints, typo, useCycle, useScroll, useInView } from '../../styles/base/_allBase.styles';
import { Container, Cursor, ButtonDiv, HeaderContainer } from './carousel.boni-day.styles';
const { StyleP, H1, H2, H3, H4 } = base;
const { SmallImage, ImgCover } = imageStyles;
// Animations
import { cursorDirectionAnimation, fadeInAndOutVariants, fadeInOutAnimation, inViewOptions } from '../../styles/base/_animations.styles';
// Components
import { BoniDayDisplay } from '../boni-day-display/boni-day-display';
import { Section } from '../section/section.component';
// Assets
import SunRise from '../../assets/logo/sunrise.png';

function generateCarouselItems(imageArray) {
	return imageArray.map((item, i) => {
		return (
			<motion.div key={item._key} cycle={true.toString()} {...fadeInOutAnimation} style={{ height: '100%', width: '100%' }}>
				<ImgCover style={{ cursor: 'none' }} src={sanitySrcUrl(item.photo)} alt={item.photo.alt} layout='fill' />
			</motion.div>
		);
	});
}

export default function CarouselBoniDay({ children, imageArray, ...props }) {
	const { cursorYSpring, cursorXSpring } = useCursorPosition();
	const [size, setSize, visible, setVisible, rotate, rotation] = useCursorChange();
	const imageCarouselItems = generateCarouselItems(imageArray);
	const [imageIndex, showHide, changeImage] = useCarousel(imageCarouselItems);
	const childArray = React.Children.toArray(children);

	const timeoutRef = useRef(null);
	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}
	useEffect(() => {
		resetTimeout();
		if (true) {
			timeoutRef.current = setTimeout(() => changeImage('next'), 5000);
		} else {
			return;
		}
		return () => {
			resetTimeout();
		};
	}, [imageIndex]);

	const { y, ref, isInView } = useParallaxInView(200);

	return (
		<Section size={'small'} nocursor={true}>
			<Container ref={timeoutRef} onMouseEnter={setVisible} onMouseLeave={setVisible}>
				<HeaderContainer ref={ref} style={{ y, opacity: isInView ? 1 : 0 }}>
					<SmallImage alt={'Boni Beach Lombok Sunrise Logo'} style={{ height: '4rem' }} src={SunRise} />
					<H2 style={{ padding: '2rem 1rem 1rem 1rem' }} color={'white'}>
						The Perfect Day at Boni Beach
					</H2>
				</HeaderContainer>
				<AnimatePresence initial={false} exitBeforeEnter>
					{showHide && (
						<motion.div>
							{/* {React.cloneElement(childArray[imageIndex], { ...props })} */}
							{imageCarouselItems[imageIndex]}
						</motion.div>
					)}
				</AnimatePresence>
				<Cursor
					visible={visible.toString()}
					animate={rotate && visible ? 'rotate' : !size && visible ? 'small' : 'exit'}
					variants={cursorDirectionAnimation}
					style={{
						translateX: cursorXSpring,
						translateY: cursorYSpring,
					}}
				/>
				<ButtonDiv left={true.toString()} onClick={() => changeImage('prev')}></ButtonDiv>
				<ButtonDiv onMouseEnter={rotation} onMouseLeave={rotation} right={true.toString()} onClick={() => changeImage('next')}></ButtonDiv>

				<BoniDayDisplay data={imageArray} onMouseEnter={setVisible} onMouseLeave={setVisible} showHide={showHide} imageIndex={imageIndex} onClick={changeImage} style={{ gridArea: 'animation' }} />
			</Container>
		</Section>
	);
}

export { CarouselBoniDay };
