// components/Stars.js

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarsContainer = styled.div`
	display: inline-block;
`;

const Star = styled(FontAwesomeIcon)`
	color: #ffc107; // gold color for stars
	margin-right: 0.5rem;
`;

const Stars = ({ rating }) => {
	return (
		<StarsContainer>
			{[...Array(5)].map((star, index) => {
				const ratingValue = index + 1;
				return <Star key={index} icon={ratingValue <= rating ? faStar : faStar} />;
			})}
		</StarsContainer>
	);
};

export default Stars;
