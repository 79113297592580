import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
// Components
import { ButtonNoLink } from '../button/button.component';
import { FormInput, FormInputBooking } from '../input/input.component';

// Styles
import { base, imageStyles, animations, containers, AnimatePresence } from '../../styles/base/_allBase.styles';
const { StyleP, SmallP, XSmallP } = base;
const { ButtonContainer } = containers;
const { ImgCover, SmallImage, WhatsAppLogo } = imageStyles;
const { motionHoverLarge } = animations;
// Amnimations
import { BoxBeCustom, Label, PopUpGuest, Icon, Bold, SlimGridRow, Row, RowPop, Col } from './booking-form.styles.js';
// Functions
import { callAll, createEventOptions } from '../../lib/libFunctions';

const BookingForm = () => {
	const [checkIn, setCheckIn] = useState('');
	const [checkOut, setCheckOut] = useState('');
	const [promoCode, setPromoCode] = useState('');
	const [adults, setAdults] = useState(1);
	const [children, setChildren] = useState(0);
	const [infants, setInfants] = useState(0);
	const [isOpen, setOpen] = useState(false);

	const router = useRouter();
	const { slug } = router.query;

	const handleBooking = () => {
		let link = 'https://secure.guestaps.com/';
		let merchant_id = 'bonibeach';
		if (checkIn && checkOut) {
			const promo = promoCode ? promoCode : 'promo_code_empty';
			location.href = `${link}${merchant_id}/hotel-filter-redirect/${checkIn}/${checkOut}/${promo}?guest=${adults}-${children}-${infants}`;
		} else {
			if (!checkIn) document.getElementById('check-in').style.borderColor = '#dc3545';
			if (!checkOut) document.getElementById('check-out').style.borderColor = '#dc3545';
		}
	};

	function handleSendEventClick(slug) {
		const eventName = 'booking_click_banner';
		const eventOptions = createEventOptions(slug);

		gtag('event', eventOptions[eventName].event, {
			event_category: eventOptions[eventName].category,
			event_label: eventOptions[eventName].label,
			page_path: eventOptions[eventName].page,
		});

		gtag('event', 'conversion', {
			send_to: 'AW-11381423161/iri1COixhvIYELn4irMq',
		});
	}

	return (
		<BoxBeCustom>
			<Row>
				<div>
					<Label id='check-in-label'>
						<XSmallP>Check in:</XSmallP>{' '}
					</Label>
					<FormInputBooking type='date' name='check-in' id='check-in' value={checkIn} onChange={(e) => setCheckIn(e.target.value)} />
				</div>
				<div>
					<Label id='check-out-label'>
						<XSmallP>Check out:</XSmallP>
					</Label>
					<FormInputBooking type='date' name='check-out' id='check-out' value={checkOut} onChange={(e) => setCheckOut(e.target.value)} />
				</div>
				<div>
					<Label>
						<XSmallP>Guest:</XSmallP>
					</Label>
					<FormInputBooking as='div' onClick={() => setOpen(!isOpen)}>
						<SmallP>
							<span id='adult-guest'>{adults}</span> Adults, <span id='children-guest'>{children}</span> Children, <span id='infant-guest'>{infants}</span> Infants
						</SmallP>
					</FormInputBooking>
				</div>
				<div>
					<Label>
						<XSmallP>Promo code:</XSmallP>
					</Label>
					<FormInputBooking type='text' className='input-be' name='promo-code' placeholder='Promo code' id='promo-code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
				</div>
				<div>
					<ButtonNoLink type='button' onClick={callAll(handleBooking, () => handleSendEventClick(slug))}>
						Book Now
					</ButtonNoLink>
				</div>
			</Row>
			<AnimatePresence>
				{isOpen && (
					<PopUpGuest {...animations.fadeInOutAnimation} id='pop-up-guest'>
						<div>
							<RowPop>
								<Col size={6}>
									<StyleP>Adult</StyleP>
									<SmallP>Ages 17 & above</SmallP>
								</Col>
								<Col size={6}>
									<SlimGridRow>
										<Icon onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}>-</Icon>
										<Bold id='adult-amount'>{adults}</Bold>
										<Icon onClick={() => setAdults(adults + 1)}>+</Icon>
									</SlimGridRow>
								</Col>
							</RowPop>
						</div>
						<div>
							<RowPop>
								<Col>
									<StyleP>Children</StyleP>
									<SmallP>Ages 2-16</SmallP>
								</Col>
								<Col>
									<SlimGridRow>
										<Icon onClick={() => setChildren(children > 0 ? children - 1 : 0)}>-</Icon>
										<Bold id='children-amount'>{children}</Bold>
										<Icon onClick={() => setChildren(children + 1)}>+</Icon>
									</SlimGridRow>
								</Col>
							</RowPop>
						</div>
						<div>
							<RowPop>
								<Col>
									<StyleP>Infants</StyleP>
									<SmallP>Under 2</SmallP>
								</Col>
								<Col>
									<SlimGridRow>
										<Icon onClick={() => setInfants(infants > 0 ? infants - 1 : 0)}>-</Icon>
										<Bold id='infant-amount'>{infants}</Bold>
										<Icon onClick={() => setInfants(infants + 1)}>+</Icon>
									</SlimGridRow>
								</Col>
							</RowPop>
						</div>
					</PopUpGuest>
				)}
			</AnimatePresence>
		</BoxBeCustom>
	);
};

export default BookingForm;
