import { styled, motion, Img, colors, breakpoints, typo } from '../../styles/base/_allBase.styles';

// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const Parent = styled.div`
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-column: 1fr;
	grid-template-rows: ${({ columns }) => `repeat(${columns}, 45vh)`};
	position: sticky;
	overflow: hidden;
	top: 10vh;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
		grid-template-columns: ${({ columns }) => `repeat(${columns}, 50vw)`};
		grid-template-rows: 1fr;
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		grid-template-columns: ${({ columns }) => `repeat(${columns}, 50vw)`};
		grid-template-rows: 1fr;
	}
	@media ${xxLargeDevices} {
	}
`;

export const FixedChild = styled(motion.div)`
	height: 100%;
	width: 100vw;
	padding: 2rem;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	background: ${colors.warm_palatte_tertiary};
	position: ${({ direction, columns }) => (direction === 'left' ? 'static' : direction === 'right' ? 'relative' : 'static')};
	top: ${({ direction, columns }) => (direction === 'left' ? 0 : direction === 'right' ? '-45vh' : 0)};
	grid-column-start: ${({ direction, columns }) => (direction === 'left' ? 1 : direction === 'right' ? 1 : 1)};
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
		grid-column-start: ${({ direction, columns }) => (direction === 'left' ? 1 : direction === 'right' ? 2 : 1)};
		grid-row-start: 1;
		height: 100%;
		width: 50vw;
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		position: static;
		grid-column-start: ${({ direction, columns }) => (direction === 'left' ? 1 : direction === 'right' ? 2 : 1)};
		grid-row-start: 1;
		height: 100%;
		width: 50vw;
	}
	@media ${xxLargeDevices} {
		grid-column-start: ${({ direction, columns }) => (direction === 'left' ? 1 : direction === 'right' ? 2 : 1)};
		grid-row-start: 1;
		height: 100%;
		width: 50vw;
	}
`;

export const Child = styled(motion.div)`
	position: relative;
	top: 45vh;
	left: -100vw;
	height: 100%;
	width: 100vw;
	padding: 2rem;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	background: ${colors.warm_palatte_tertiary};
	grid-column-start: ${({ direction, columns, position }) => (direction === 'left' ? 1 + position : direction === 'right' ? position : 1)};
	grid-row-start: 1;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		position: static;
		height: 100%;
		width: 100%;
		padding: 2rem;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		background: ${colors.warm_palatte_tertiary};
		grid-column-start: ${({ direction, columns, position }) => (direction === 'left' ? 1 + position : direction === 'right' ? position : 1)};
		grid-row-start: 1;
	}
	@media ${xxLargeDevices} {
	}
`;

export const TextContainer = styled(motion.div)`
	position: relative;
	top: 3rem;
	padding: 0rem 0rem;
	@media ${xxSmallDevices} {
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		padding: 0rem 6rem;
	}
	@media ${mediumDevicesLandscape} {
	}
	@media ${largeDevices} {
	}
	@media ${extraLargeDevices} {
		margin-top: -20rem;
		padding: 0rem 4rem;
	}
	@media ${xxLargeDevices} {
	}
`;
