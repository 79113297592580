import { styled, motion, Img, colors, breakpoints, typo } from '../../styles/base/_allBase.styles';

// Breakpoints
const { xxSmallDevices, xxSmallDevicesLandscape, extraSmallDevices, extraSmallDevicesLandscape, mediumDevices, mediumDevicesLandscape, largeDevices, extraLargeDevices, xxLargeDevices } = breakpoints;

export const DisplayButtonContainer = styled(motion.div)`
	position: absolute;
	height: 16vh;
	width: 90vw;
	left: 5vw;
	bottom: 6vh;
	z-index: 1;
	cursor: pointer;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 13vh 3vh;
	grid-template-areas:
		'time1 time2 time3 time4'
		'animation animation animation animation';
	gap: 0.5rem;
	@media ${xxSmallDevices} {
		bottom: 4rem;
	}
	@media ${xxSmallDevicesLandscape} {
	}
	@media ${extraSmallDevices} {
		grid-template-rows: 13vh 3vh;
	}
	@media ${extraSmallDevicesLandscape} {
	}
	@media ${mediumDevices} {
		grid-template-rows: 6vh 4vh;
	}
	@media ${largeDevices} {
		grid-template-rows: 7vh 5vh;
		height: 12vh;
	}
	@media ${extraLargeDevices} {
		grid-template-rows: 8vh 6vh;
		height: 14vh;
	}
	@media ${xxLargeDevices} {
	}
`;
