import React from 'react';
import PropTypes from 'prop-types';

import { InputText, Group, Label, TextArea, CheckBoxInput, LabelSlider, Input, Slider } from './input.styles';

export const FormInput = ({ onChange, label, ...otherProps }) => (
	<Group className='group'>
		<InputText className='form-input' onChange={onChange} {...otherProps} />
		{label ? <Label label={otherProps.value}>{label}</Label> : null}
	</Group>
);

export const FormInputBooking = ({ onChange, label, ...otherProps }) => (
	<Group className='group'>
		<InputText className='form-input' onChange={onChange} {...otherProps} />
		{label ? <Label label={otherProps.value}>{label}</Label> : null}
	</Group>
);

export const FormTextIput = ({ onChange, label, ...otherProps }) => (
	<Group className='group'>
		<TextArea className='form-input' onChange={onChange} {...otherProps} />
		{label ? <Label label={otherProps.value}>{label}</Label> : null}
	</Group>
);

export const CheckBox = ({ checked, onClick, ...otherProps }) => <CheckBoxInput checked={checked} onClick={onClick} type='checkbox' {...otherProps} />;

export function ToggleSwitch({ value, checked, onChange, onClick, name, id, disabled, title, size }) {
	return (
		<LabelSlider htmlFor={id} disabled={disabled} title={title} size={size}>
			<Input id={id} type='checkbox' name={name} value={value} disabled={disabled} checked={checked} onChange={onChange} />
			<Slider />
		</LabelSlider>
	);
}

FormInput.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
};

FormTextIput.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
};

CheckBox.propTypes = {
	description: PropTypes.func,
	checked: PropTypes.bool,
};
