import React, { useRef } from 'react';
import { useParallaxInView, useCursorChange, useWindowDimensions } from '../../lib/hooks';
import { Parent, Child, FixedChild, TextContainer } from './scroll.style';
import Content from '../block-content/block-content';
import { sanitySrcUrl } from '../../lib/libFunctions';

// Styles
import { base, imageStyles, animations, useTransform, useScroll } from '../../styles/base/_allBase.styles';
const { H3, StyleP, CenterDiv } = base;
const { ImgCover } = imageStyles;
const { inViewOptions } = animations;
// Components
import AnimatedCharacters from '../../components/animated-characters/animated-text';
import { Section } from '../section/section.component';
import { CustomButton } from '../button/button.component';
function transformDistance(length, direction, width) {
	if (width < 1025) {
		if (length === 3) {
			return direction === 'left' ? '-100vw' : direction === 'right' ? '100vw' : '-50vw';
		} else if (length > 3) {
			return direction === 'left' ? `-${(length - 3) * 50 + 50}vw` : direction === 'right' ? `0vw` : `-${(length - 3) * 50 + 50}vw`;
		} else {
			return 'error';
		}
	} else {
		if (length === 3) {
			return direction === 'left' ? '-50vw' : direction === 'right' ? '0vw' : '-50vw';
		} else if (length > 3) {
			return direction === 'left' ? `-${(length - 3) * 50 + 50}vw` : direction === 'right' ? `0vw` : `-${(length - 3) * 50 + 50}vw`;
		} else {
			return 'error';
		}
	}
}

function transformStart(length, direction, width) {
	if (width < 1025) {
		return direction === 'left' ? '0vw' : `0vw`;
	} else {
		return direction === 'left' ? '0vw' : `-${(length - 2) * 50}vw`;
	}
}

function generateItems(text, array) {
	const { y, ref, isInView } = useParallaxInView(50);
	const [size, setSize, visible, setVisible, rotate, rotation] = useCursorChange();
	const newArray = [text, ...array];
	return newArray.map((child, i) => {
		if (i === 0) {
			return (
				<CenterDiv ref={ref} style={{ y }}>
					<TextContainer>
						<AnimatedCharacters>
							<H3>{child.header}</H3>
						</AnimatedCharacters>
						<StyleP {...animations.fadeInAndOutVariants} left='true'>
							<Content blocks={child.text} />
						</StyleP>
						<CustomButton href={child.slug.current} onMouseEnter={setVisible} onMouseLeave={setVisible}>
							Discover
						</CustomButton>
					</TextContainer>
				</CenterDiv>
			);
		} else if (i > 0) {
			return <ImgCover style={{ cursor: 'none' }} alt={child.alt} src={sanitySrcUrl(child)} layout='fill' />;
		}
	});
}

export default function HorizontalScroll({ data, direction, ...props }) {
	const ref = React.useRef(null);
	const { width, height } = useWindowDimensions();
	const header = data.title;
	const text = data.description;
	const slug = data.slug;
	const imageArray = data.imageArray;
	const { scrollYProgress } = useScroll({ target: ref });
	const childrenArray = generateItems({ header, text, slug }, imageArray);
	const x = useTransform(scrollYProgress, [0, 1], [transformStart(childrenArray.length, direction, width), transformDistance(childrenArray.length, direction, width)]);

	return (
		<Section size={'auto'} nocursor={props.nocursor}>
			<div
				ref={ref}
				style={{
					width: '100vw',
					height: `${childrenArray.length}00vh`,
				}}
			>
				<Parent direction={direction} columns={childrenArray.length}>
					<FixedChild
						direction={direction}
						columns={childrenArray.length}
						style={{
							zIndex: 1,
						}}
					>
						{childrenArray[0]}
					</FixedChild>

					{childrenArray.map((child, i) => {
						if (i > 0) {
							return (
								<Child
									direction={direction}
									key={i}
									position={i}
									style={{
										x,
									}}
								>
									{childrenArray[i]}
								</Child>
							);
						}
					})}
				</Parent>
			</div>
		</Section>
	);
}

// export { HorizontalScroll };
