import React from 'react';
import { CustomButton } from '../button/button.component';
import DragSlider from '../drag-slider/drag-slider';
import { Section } from '../section/section.component';
import { GridCardSection, TextContainer } from './drag-slider-section.styles';
import AnimatedCharacters from '../animated-characters/animated-text';
import { base, containers, motion, useScroll, animations } from '../../styles/base/_allBase.styles';
import { useParallaxInView } from '../../lib/hooks';
import { sanitySrcUrl } from '../../lib/libFunctions';
const { H2, StyleP, CenterDiv } = base;
const { Card, CardFooter } = containers;
import Content from '../block-content/block-content';

export default function DragSliderSection({ data }) {
	const { y, ref, isInView } = useParallaxInView();
	const { header, text, array } = data;
	return (
		<Section cursorsize={'small'} size={'large'}>
			<GridCardSection ref={ref} style={{ y, zIndex: 10, position: 'relative' }}>
				<TextContainer style={{ y, zIndex: 100, position: 'relative' }}>
					<CenterDiv>
						{/* <AnimatedCharacters> */}
						<H2>{header}</H2>
						{/* </AnimatedCharacters> */}
						<StyleP>
							<Content blocks={text} />
						</StyleP>
					</CenterDiv>
				</TextContainer>
				<DragSlider styles={{ margin: '4rem 0rem' }}>
					{array.map((item, i) => {
						return (
							<Card key={i} imgurl={sanitySrcUrl(item.photo)}>
								<CardFooter>
									<CustomButton href={item.slug.current} style={{ width: '80%' }}>
										{item.label}
									</CustomButton>
								</CardFooter>
							</Card>
						);
					})}
				</DragSlider>
			</GridCardSection>
		</Section>
	);
}

// export { DragSliderSection };
