import React from 'react';
// Components
import { useCursorChange } from '../../lib/hooks';
import CursorComp from '../cursor/cursor';
// Styles
import { base, styled, AnimatePresence, motion, Img, colors, typo } from '../../styles/base/_allBase.styles';
import { DisplayButtonContainer } from './boni-day-display.styles';
const { StyleP, H4, H5 } = base;
// Animations
import { cursorDirectionAnimation, fadeInAndOutVariants } from '../../styles/base/_animations.styles';

const BoniDayDisplay = ({
	data = [],
	onClick,
	imageIndex,
	showHide,
	isOpen = false,
	width = '100%',
	height = '100%',
	strokeWidth = 3,
	color = 'white',
	transition = null,
	lineProps = null,
	...props
}) => {
	const variant = isOpen ? 'opened' : 'closed';

	lineProps = {
		stroke: color,
		strokeWidth: strokeWidth,
		vectorEffect: 'non-scaling-stroke',
		initial: 'closed',
		animate: variant,
		transition,
		...lineProps,
	};
	const unitHeight = 4;
	const unitWidth = 100;
	const unitSpacing = ['12%', '37%', '62%', '88%'];

	const circleVariants = {
		enter: {
			opacity: 0,
			pathLength: 0,
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
		center: {
			opacity: 1,
			pathLength: 1.2,
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
		exit: {
			opacity: 0,
			pathLength: 0,
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
	};

	const [size, setSize, visible, setVisible] = useCursorChange();

	return (
		<DisplayButtonContainer style={{ cursor: 'none' }} onMouseEnter={setVisible} onMouseLeave={setVisible}>
			{data.map((info, i) => {
				return (
					<div name={'topDiv'} key={i} style={{ height: '15vh' }}>
						<AnimatePresence key={i} initial={true} exitBeforeEnter>
							{showHide && (
								<div>
									<motion.div
										onClick={() => onClick(i)}
										style={{ visibility: `${imageIndex === i ? 'visible' : 'hidden'}`, gridArea: `time${i + 1}` }}
										initial='enter'
										animate='center'
										exit='exit'
										variants={circleVariants}
									>
										<H5 center='true' color='white'>
											{info.time}
										</H5>
									</motion.div>
									<motion.div
										onClick={() => onClick(i)}
										style={{ visibility: `${imageIndex === i ? 'visible' : 'hidden'}`, gridArea: `description${i + 1}` }}
										initial='enter'
										animate='center'
										exit='exit'
										variants={circleVariants}
									>
										<StyleP center='true' color='white'>
											{info.text}
										</StyleP>
									</motion.div>
								</div>
							)}
						</AnimatePresence>
					</div>
				);
			})}

			<motion.svg viewBox={`0 0 ${unitWidth} ${unitHeight}`} overflow='visible' preserveAspectRatio='none' width={width} height={height} {...props}>
				<motion.line x1='0' x2={unitWidth} y1='2' y2='2' {...lineProps} />
				<AnimatePresence initial={true} exitBeforeEnter>
					{showHide && (
						<motion.circle
							whileHover={{ r: 1.75 }}
							cx={unitSpacing[imageIndex]}
							cy='50%'
							r='1.5'
							stroke={colors.font_white}
							strokeWidth='0.3'
							fill={'transparent'}
							initial='enter'
							animate='center'
							exit='exit'
							variants={circleVariants}
						/>
					)}
				</AnimatePresence>

				{unitSpacing.map((position, i) => {
					return (
						<motion.circle
							whileTap={{ scale: 3 }}
							onMouseEnter={setSize}
							onMouseLeave={setSize}
							key={i}
							onClick={() => onClick(i)}
							cx={position}
							cy='50%'
							r='0.45'
							strokeWidth='0.2'
							fill={colors.font_white}
						/>
					);
				})}
			</motion.svg>
			<CursorComp size={size} visible={visible} />
		</DisplayButtonContainer>
	);
};

export { BoniDayDisplay };
